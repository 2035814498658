import React, {useState, useEffect, useRef} from 'react'

import Img from "../imgFrankenstein"

import "./FrankensteinsMonster.scss"

const FrankensteinsMonster = ({imgData, gsap, ScrollTrigger}) => {
    const [moritz, setMoritz] = useState(true)
    const [nikita, setNikita] = useState(true)
    const [kristina, setKristina] = useState(true)

    const kristinaHandeler = () => {
        setMoritz(false)
        setNikita(false)
        setKristina(true)
    }

    const moritzHandeler = () => {
        setMoritz(true)
        setNikita(false)
        setKristina(false)
    }

    const nikitaHandeler = () => {
        setMoritz(false)
        setNikita(true)
        setKristina(false)
    }

    const allTeamHandeler = () => {
        setMoritz(true)
        setNikita(true)
        setKristina(true)
    }

    const teamInfo = [
        {person: {
            firstName: "Nikita",
            lastName: "Grabin",
            position: "operations director",
            email: "nikita@hemdundfliege.com",
            number: "+49 160 97074357",
            socialMedia: [
                {id:"insta", instagram: true, link: ""},
                {id:"linkedin", linkedin: true, link:""},
            ]
            
    }},
    {person: {
        firstName: "Kristina",
        lastName: "Lāce Raibarte",
        position: "art director",
        email: "kristina@hemdundfliege.com",
        number: "+371 29675325",
        socialMedia: [
            {id:"insta", instagram: true, link: ""},
            {id:"linkedin", linkedin: true, link:""},
            {id:"behance", linkedin: true, link:""},
        ]
            
    }},
    {person: {
        firstName: "Moritz",
        lastName: "Wright",
        position: "developer",
        email: "moritz@hemdundfliege.com",
        number: "+49 157 38831179",
        socialMedia: [
            {id:"insta", instagram: true, link: ""},
            {id:"linkedin", linkedin: true, link:""},
            {id:"behance", linkedin: true, link:""},
        ]
            
    }}
]

const Mailto = ({ email, subject, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}`}>{children}</a>
    );
};

const nikitaInfo = teamInfo[0].person
const kristinaInfo = teamInfo[1].person
const moritzInfo = teamInfo[2].person

    let moritz1 = useRef()
    let moritz2 = useRef()
    let moritz3 = useRef()

    let nikita1 = useRef()
    let nikita2 = useRef()
    let nikita3 = useRef()

    let kristina1 = useRef()
    let kristina2 = useRef()
    let kristina3 = useRef()
    let kristina4 = useRef()
    let nosePictureKristina = useRef()

    
    let nikitaInfoContainer = useRef()
    let kristinaInfoContainer = useRef()
    let moritzInfoContainer = useRef()

    useEffect(() => {
        if(nikita === false && moritz === false) {
            gsap.to([nikita1, nikita2, nikita3, nikitaInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })

            gsap.to([moritz1, moritz2, moritz3, moritzInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })

            gsap.to([kristina1], {
                duration: 0.8,
                opacity: 0,
            })

            gsap.to([kristina2, kristina3, kristina4, kristinaInfoContainer], {
                duration: 0.8,
                opacity: 1,
                pointerEvents: "auto"
            })
        } else if(nikita === false && kristina === false) {
            gsap.to([nikita1, nikita2, nikita3, nikitaInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })

            gsap.to([moritz1, moritz2, moritz3, moritzInfoContainer], {
                duration: 0.8,
                opacity: 1,
                pointerEvents: "auto"
            })

            gsap.to([kristina1, kristina2, kristina3, kristina4, kristinaInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })
        } else if(moritz === false && kristina === false) {
            gsap.to([nikita1, nikita2, nikita3, nikitaInfoContainer], {
                duration: 0.8,
                opacity: 1,
                ointerEvents: "auto"
            })

            gsap.to([moritz1, moritz2, moritz3, moritzInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })

            gsap.to([kristina1, kristina2, kristina3, kristina4, kristinaInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })
        } else if(moritz === true && kristina === true && kristina === true) {
            gsap.to([nikitaInfoContainer, moritzInfoContainer, kristinaInfoContainer], {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none"
            })
            gsap.to([nikita1, nikita2, nikita3], {
                duration: 0.8,
                opacity: 1
            })

            gsap.to([moritz1, moritz2, moritz3], {
                duration: 0.8,
                opacity: 1
            })

            gsap.to([kristina1, kristina2, kristina3, kristina4], {
                duration: 0.8,
                opacity: 1
            })
        }
    }, [moritz, kristina, nikita])
    
    return (
        <>
            <div className="monster-container">
                        <div className="picture-container">
                            <div className="picture-wrapper">
                                    <div className="img-wrapper" ref={el => moritz1 = el}>
                                        <Img image={imgData[0].img} />
                                    </div>
                                <div className="img-layer-mask"></div>
                            </div>
                        </div>

                        <div className="picture-container">
                            <div className="picture-wrapper">
                                <div className="img-wrapper" ref={el => moritz2 = el}>
                                    <Img image={imgData[1].img} />
                                </div>
                                <div className="img-layer-mask"></div>
                            </div>
                        </div>

                        <div className="picture-container">
                            <div className="picture-wrapper">
                                    <div className="img-wrapper" ref={el => moritz3 = el}>
                                        <Img image={imgData[2].img} />
                                    </div>
                                <div className="img-layer-mask"></div>
                            </div>
                        </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => nikita1 = el}>
                                <Img image={imgData[3].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => nikita2 = el}>
                                <Img image={imgData[4].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => nikita3 = el}>
                                <Img image={imgData[5].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => kristina1 = el}>
                                <Img image={imgData[6].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => kristina2 = el}>
                                <Img image={imgData[7].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => kristina3 = el}>
                                <Img image={imgData[8].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>

                    <div className="picture-container">
                        <div className="picture-wrapper">
                            <div className="img-wrapper" ref={el => kristina4 = el}>
                                <Img image={imgData[9].img} />
                            </div>
                            <div className="img-layer-mask"></div>
                        </div>
                    </div>
            </div>
            <div className="team-info-container">
                
                <div className="info-nikita-container" ref={el => nikitaInfoContainer = el}>
                    <div className="position-container">
                        {nikitaInfo.position.split(" ").map((el, i) => (
                            <div key={i} className="position-wrapper">
                                <h2>{el}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="name-container">
                        <div className="name-wrapper">
                            <h3>{nikitaInfo.firstName}</h3>
                        </div>
                        <div className="name-wrapper">
                            <h3>{nikitaInfo.lastName}</h3>
                        </div>
                    </div>
                    <div className="contact-container">
                        <div className="email-wrapper">
                            <Mailto email={nikitaInfo.email} subject="Just wanted to say hi.">
                                <h3>{nikitaInfo.email}</h3>
                            </Mailto>
                        </div>
                        <div className="number-wrapper">
                            <h3>{nikitaInfo.number}</h3>
                        </div>
                    </div>
                </div>

                <div className="info-kristina-container" ref={el => kristinaInfoContainer = el}>
                    <div className="position-container">
                        {kristinaInfo.position.split(" ").map((el, i) => (
                            <div key={i} className="position-wrapper">
                                <h2>{el}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="name-container">
                        <div className="name-wrapper">
                            <h3>{kristinaInfo.firstName}</h3>
                        </div>
                        <div className="name-wrapper">
                            <h3>{kristinaInfo.lastName}</h3>
                        </div>
                    </div>
                    <div className="contact-container">
                        <div className="email-wrapper">
                            <Mailto email={kristinaInfo.email} subject="Just wanted to say hi.">
                                <h3>{kristinaInfo.email}</h3>
                            </Mailto>
                        </div>
                        <div className="number-wrapper">
                            <h3>{kristinaInfo.number}</h3>
                        </div>
                    </div>
                </div>

                <div className="info-moritz-container" ref={el => moritzInfoContainer = el}>
                    <div className="position-container">
                        {moritzInfo.position.split(" ").map((el, i) => (
                            <div key={i} className="position-wrapper">
                                <h2>{el}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="name-container">
                        <div className="name-wrapper">
                            <h3>{moritzInfo.firstName}</h3>
                        </div>
                        <div className="name-wrapper">
                            <h3>{moritzInfo.lastName}</h3>
                        </div>
                    </div>
                    <div className="contact-container">
                        <div className="email-wrapper">
                            <Mailto email={moritzInfo.email} subject="Just wanted to say hi.">
                                <h3>{moritzInfo.email}</h3>
                            </Mailto>
                        </div>
                        <div className="number-wrapper">
                            <h3>{moritzInfo.number}</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div className="monster-navbar">
                <nav>
                    <p className="navigation-description">CLICK ON IMAGE TO DISCOVER</p>
                    <ul>
                        <li onClick={allTeamHandeler}>all</li>
                        <li onClick={nikitaHandeler}>clients</li>
                        <li onClick={kristinaHandeler}>design</li>
                        <li onClick={moritzHandeler}>code</li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default FrankensteinsMonster
