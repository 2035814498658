import React from 'react'

import "./CountryList.scss"

const CountryList = ({list, headline}) => {
    
    return (
        <div className="country-list-container">
            <div className="country-list-head-wrapper">
                <h2>{headline}</h2>
            </div>
            <div className="list-container">
                <ul>
                    {list.map(item => (
                        <li key={item.id}><h3>{`${item.city}, ${item.country}`}</h3></li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default CountryList
