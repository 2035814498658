import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({image, refId}) => {
  const data = useStaticQuery(graphql`
    query {
      kristinaOne: file(relativePath: {eq: "kristina-1.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kristinaTwo: file(relativePath: {eq: "kristina-2.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kristinaThree: file(relativePath: {eq: "kristina-3.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kristinaFour: file(relativePath: {eq: "kristina-4.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      moritzOne: file(relativePath: {eq: "moritz-1.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      moritzTwo: file(relativePath: {eq: "moritz-2.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      moritzThree: file(relativePath: {eq: "moritz-3.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      nikitaOne: file(relativePath: {eq: "nikita-1.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      nikitaTwo: file(relativePath: {eq: "nikita-2.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      nikitaThree: file(relativePath: {eq: "nikita-3.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imgSwitch = (imgCase) => {
    switch(imgCase) {
      case "kristina-one":
        return <Img fluid={data.kristinaOne.childImageSharp.fluid} />;
      case "kristina-two":
        return <Img fluid={data.kristinaTwo.childImageSharp.fluid} />;
      case "kristina-three":
        return <Img fluid={data.kristinaThree.childImageSharp.fluid} />;        
      case "kristina-four":
        return <Img fluid={data.kristinaFour.childImageSharp.fluid} />;
      case "moritz-one":
        return <Img fluid={data.moritzOne.childImageSharp.fluid} />;
      case "moritz-two":
        return <Img fluid={data.moritzTwo.childImageSharp.fluid} />;
      case "moritz-three":
        return <Img fluid={data.moritzThree.childImageSharp.fluid} />;
      case "nikita-one":
        return <Img fluid={data.nikitaOne.childImageSharp.fluid} />;
      case "nikita-two":
        return <Img fluid={data.nikitaTwo.childImageSharp.fluid} />;
      case "nikita-three":
        return <Img fluid={data.nikitaThree.childImageSharp.fluid} />;
      default:
        console.log("No image, something went wrong")         
    }
  }

  return (
      imgSwitch(image)
  )
}

export default Image
