import React, {useRef, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SEO from "../components/seo"

import OurTeamFlag from "../images/our-team-flag.svg"

import Layout from "../components/layout"
import BackgroundElement from "../components/BackgroundElement/BackgroundElement"
// import SpecialButton from "../components/SpecialButton/SpecialButton"
import FrankensteinsMonster from "../components/FrankensteinsMonster/FrankensteinsMonster"
import TextHeadline from "../components/GeneralHeadline/TextHeadline"
import CountryList from "../components/CountryList/CountryList"
import Headline from "../components/GeneralHeadline/GeneralHeadline"

import backgroundElementTeamTwo from "../images/background-element-team-two.svg"

const imgData = [
    {id: 1, img: "moritz-two", type: "moritz"},
    {id: 2, img: "moritz-one", type: "moritz"},
    {id: 3, img: "moritz-three", type: "moritz"},
    {id: 4, img: "nikita-one", type: "nikita"},
    {id: 7, img: "nikita-two", type: "nikita"},
    {id: 8, img: "nikita-three", type: "nikita"},
    {id: 5, img: "kristina-three", type: "kristina"},
    {id: 6, img: "kristina-two", type: "kristina"},
    {id: 9, img: "kristina-four", type: "kristina"},
    {id: 10, img: "kristina-one", type: "kristina"},
]

const conceptHeadline = [
    {id: 1, title: "consists of"},
    {id: 2, title: "digital"},
    {id: 3, title: "nomads"},
    {id: 4, title: "connectect through"},
    {id: 5, title: "online"},
    {id: 6, title: "coworking"},
]

const teamSubheadData = "OF OUR/COMUNITY/ARE BASED/IN";
const teamSubhead = teamSubheadData.split("/");

const countryList = {
        headline: "other members",
        list: [
            {id: "member-1", city: "berlin", country: "de"},
            {id: "member-2", city: "hamburg", country: "de"},
            {id: "member-3", city: "riga", country: "lv"},
            {id: "member-4", city: "kiev", country: "ukr"},
            {id: "member-5", city: "sydney", country: "au"},
            {id: "member-6", city: "amsterdam", country: "nl"},
            {id: "member-7", city: "groningen", country: "nl"},
            {id: "member-8", city: "rotterdam", country: "nl"},
            {id: "member-9", city: "vienna", country: "aut"},
            {id: "member-10", city: "whitchurch", country: "uk"},
            {id: "member-11", city: "malaga", country: "es"},
            {id: "member-12", city: "tifilis", country: "geo"},
            {id: "member-13", city: "managua", country: "nic"},
            {id: "member-14", city: "sao paulo", country: "bra"},
            {id: "member-15", city: "buenos aires", country: "arg"},
            {id: "member-16", city: "montevideo", country: "uy"},
            {id: "member-17", city: "rome", country: "it"}
        ]
}

const {headline, list} = countryList

const Team = () => {
    let revealRef = useRef([])

    revealRef.current = []

    const addToReveal = (el) => {
        if(el && !revealRef.current.includes(el)) {
            revealRef.current.push(el)
        }
    }

    useEffect(() => {
        gsap.to(revealRef.current, {
            y: "0%",
            duration:0.4,
            delay: .2,
            ease: "Power3.easeInOut",
            stagger:  {
                amount: .6
            }
        })
    }, [])

    return (
        <Layout site="layout-container-team">
            <SEO title="Team" />
            <div className="background-element-container-team">
                <BackgroundElement />
            </div>
            <div className="background-element-team-two-container">
                <img src={backgroundElementTeamTwo} />
            </div>
            <img className="our-team-flag" src={OurTeamFlag} alt="team-flag-hend-und-fliege" />
            <div className="team-text-headline-container">
                    {conceptHeadline.map(line => (
                        <TextHeadline key={line.id} text={line} site="concept" refId={addToReveal} />
                    ))}
            </div>
            <div className="team-monster-container">
                <FrankensteinsMonster imgData={imgData} gsap={gsap} ScrollTrigger={ScrollTrigger} />
            </div>
            <div className="team-sub-head-description">
                {teamSubhead.map((line, i) => (
                    <Headline key={i} line={line} />
                ))}
            </div> 
            <div className="team-country-list-container">
                <CountryList list={list} headline={headline} />
            </div>
        </Layout>
    )
}

export default Team
